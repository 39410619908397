<template>
  <MyAccountTemplate>
    <template #header> Moje dane</template>
    <template #content>
      <form class="user-settings" @submit.prevent="onSubmit">
        <UserAttributesForm :is-email-disabled="true" />

        <p v-if="isErrorVisible" class="user-settings__error">
          Wypełnij wszystkie wymagane pola
        </p>

        <div class="user-settings__submit">
          <button
            class="button"
            :class="{
              'button--loading': isSubmitting,
            }"
          >
            Zapisz
          </button>
        </div>
      </form>
    </template>
  </MyAccountTemplate>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import UserAttributesForm from '@/vue/components/user-attributes-form/user-attributes-form.vue';
import MyAccountTemplate from '@/vue/components/account/my-account-template.vue';

export default {
  name: 'UserSettings',

  components: { MyAccountTemplate, UserAttributesForm },

  data: () => ({
    isSubmitting: false,
    hasBeenSubmitted: false,
  }),

  computed: {
    ...mapGetters('userAttributesForm', {
      userFormAttributes: 'attributes',
      $v: '$v',
    }),

    isErrorVisible() {
      return this.hasBeenSubmitted && this.$v.$anyError;
    },
  },

  created() {
    this.setRequiredState({
      isInvoiceRequired: false,
      isEmailRequired: true,
    });
  },

  methods: {
    ...mapMutations('userAttributesForm', {
      touchAll: 'touchAll',
      setRequiredState: 'setRequiredState',
    }),

    ...mapActions('user', {
      updateAttributes: 'updateAttributes',
    }),

    onSubmit() {
      this.hasBeenSubmitted = false;
      this.touchAll();

      if (this.$v.$anyError) {
        this.hasBeenSubmitted = true;
        return;
      }

      this.isSubmitting = true;
      this.updateAttributes(this.userFormAttributes).finally(() => {
        this.isSubmitting = false;
      });
    },
  },
};
</script>

<style lang="scss">
.user-settings {
  width: 50%;

  &__error {
    color: red;
  }

  &__submit {
  }
}
</style>
